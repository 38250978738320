<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
  >
    <div style="padding: 0 10px">
      <el-row>
        <el-col class="item-title"
          ><span class="item-text1">课程名称：</span>
          <span class="item-text2"> {{ item.title }}</span></el-col
        >
        <el-col class="item-title"
          ><span class="item-text1">课程分类：</span>
          <span class="item-text2"> {{ item.courseGoodsName }}</span></el-col
        >
        <el-col class="item-title"
          ><span class="item-text1">课时总数：</span>
          <span class="item-text2"> {{ item.amount }}</span></el-col
        >
        <el-col class="item-title"
          ><span class="item-text1">课节数：</span>
          <span class="item-text2"> {{ item.unitAmount }}</span></el-col
        >
        <el-col class="item-title"
          ><span class="item-text1">在读班级数：</span>
          <span class="item-text2"> {{ item.classAmount }}</span></el-col
        >
        <el-col class="item-title"
          ><span class="item-text1">在读学员数：</span>
          <span class="item-text2"> {{ item.studentAmount }}</span></el-col
        >
      </el-row>
    </div>
  </el-drawer>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: () => "详情",
    },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      item: {},
    };
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    initData(item) {
      this.item = { ...item };
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
.item-text1 {
  font-weight: bold;
}
</style>