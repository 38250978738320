<template>
  <div class="outer-style">
    <el-card :size="size" shadow="never">
      <el-form :inline="true" :model="searchForm">
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.courseName"
            placeholder="课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.courseGoodsName"
            placeholder="课程分类"
          ></el-input>
        </el-form-item>
        <span>
          <el-button @click="resetForm">重置</el-button>
          <el-button type="primary" @click="getListHandler">查询</el-button>
        </span>
      </el-form>
      <el-table :size="size" :border="true" :data="tableData">
        <el-table-column align="center" prop="title" label="课程名称">
         <template slot-scope="scope">
            <el-tooltip :content="scope.row.title" placement="top">
              <span class='text-oneline'>
                {{ scope.row.title }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="courseGoodsName" label="课程分类"/>
        <el-table-column align="center" prop="amount" label="课时总数" />
        <el-table-column align="center" prop="unitAmount" label="课节数" />
        <el-table-column align="center" prop="classAmount" label="在读班级数" />
        <el-table-column
          align="center"
          prop="studentAmount"
          label="在读学员数"
        />
        <el-table-column align="center" prop="action" label="操作">
          <template slot-scope="scope">
            <el-button
              style="margin-left: 8px"
              type="text"
              @click="openDetailModal(scope.row)"
              >课程详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <CourseDetailModal ref="detail" title="课程详情" />
  </div>
</template>
<script>
import { getCourseLists} from "@/api/businesscourse/course";
import CourseDetailModal from "./components/CourseDetailModal";
export default {
  components: { CourseDetailModal },
  data() {
    return {
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { courseName: null, courseGoodsName: null },
    };
  },
  created() {
    this.getListHandler();
  },
  methods: {
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getCourseLists({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        courseName: null,
        courseGoodsName: null,
      };
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>
