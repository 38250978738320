var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.drawer,
        direction: _vm.direction,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "0 10px" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", { staticClass: "item-text1" }, [
                  _vm._v("课程名称："),
                ]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.title)),
                ]),
              ]),
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", { staticClass: "item-text1" }, [
                  _vm._v("课程分类："),
                ]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.courseGoodsName)),
                ]),
              ]),
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", { staticClass: "item-text1" }, [
                  _vm._v("课时总数："),
                ]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.amount)),
                ]),
              ]),
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", { staticClass: "item-text1" }, [_vm._v("课节数：")]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.unitAmount)),
                ]),
              ]),
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", { staticClass: "item-text1" }, [
                  _vm._v("在读班级数："),
                ]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.classAmount)),
                ]),
              ]),
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", { staticClass: "item-text1" }, [
                  _vm._v("在读学员数："),
                ]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.studentAmount)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }